.BtnContact{
    background-color: var(--color1);
    padding: 13px;
    border: none;
    border-radius: 30rem;
    color: var(--blanco);
    font-size: 17px;
    margin-top: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 13rem;
}

@media (max-width: 800px) {
    .BtnContact{
        width: 9rem;
    }
    
}