#swiper_container_images{
    padding: 40px ;
    width: 100%;
}

#SwiperSlide-scroll-image{
    border-radius: 15px;
    width: 14rem;
    margin: 1rem 2rem 0 1rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.156);
    overflow: hidden;
}

#SwiperSlide-scroll-image img{
   width: 100%;
   height: 100%;
   object-fit: contain;

   
}

.ScrollDemos{
   
    padding: 40px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
    text-align: center;
    gap: 2rem;
}
.ScrollDemosText{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
    text-align: center;
    gap: 1rem;
}
@media (max-width: 1024px) {
    .ScrollDemos{
   
        padding: 20px 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        gap: 0;
    }
    .ScrollDemosText{
        padding: 0px 7%;
    }
    #SwiperSlide-scroll-image{
        width: 13rem;
        margin: 1rem 1rem 0 1rem;
    }
}