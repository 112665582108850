.heroContain{
    height: 100vh;
    background-image: url(../../images//bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    height: 120vh;
    width: 100%;
    margin: auto;
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 5rem;
    padding: 100px 6%;
    align-items: center;
    overflow: hidden;
}
.deFlexBtns{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.img{
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    
}
.img img{
    display: flex;
    width: auto;
    height: 80vh;
    margin-right: -40%;
}

.deFlex{
    display: flex;
    align-items: center;
}
.deFlex img{
    height: 5rem;
}
.heroText h1{
    color: var(--color1);
    font-size: 45px;
}

.heroText{
    display: flex;
    flex-direction: column;
     gap: 1rem;

}

.hr{
    height: 7px;
    background-color: var(--color1);
    border: none;
    border-radius: 10px;
    width: 10rem;
}


.heroText p{
    color: var(--text-color2);
    
    padding-top: 10px;
   
}
.heroText h4{
    color: var(--text-color2);
    padding-top: 10px;
    font-size: 17px;
    font-weight: 400;
    width: 80%;
}

.heroText span{
    color: var(--color1);

   
}
@media (max-width: 800px) {
    .deFlexBtns{
       
        gap: 1rem;
    }
    .img{
      
        justify-content: center;
      
        
    }
    .heroContain{
     height: 100%;
     padding: 40px 6%;
     background-image: url(../../images/bgmobile.png);
     border-radius: 0 30px 0 30px;
     gap: 0;
     display: flex;
     flex-direction: column;
}

    .deFlex img{
        height: 3rem;
    }
    .heroText h1{
        color: var(--color1);
        font-size: 35px;
        
    }
   
    .img img{
        display: flex;
        width: auto;
        height: 50vh;
    }
    .heroText p{
        font-size: 14px;
        line-height: 1.3rem;
     
    }
    .heroText h4{
        font-size: 14px;
      
       
    }
}