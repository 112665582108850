

.videoContain {
    padding: 50px 7%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin: 0 auto; 
}

.videoPc {
   
    height: auto;
    margin-top: 2rem;
    height: 60vh;
    border-radius: 20px!important;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.156);
}
.parradoSec{
    width: 50%;
    color: var(--text-color2);
}
.buttonContainer{
    background-color: var(--gris2);
    width: 20rem;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.buttonContainer button{
    background-color: var(--gris2);
    color: var(--text-color2);
    padding: 10px 10px;
    width: 100%;
    border: none;
    cursor: pointer;
    border-radius: 10rem;
}

.activebtn{
    background-color: var(--color1)!important;
    color: var(--blanco)!important;
}

@media (max-width: 1024px) {
    .videoPc {
       height: auto;
       width: 100%;
       border-radius: 10px!important;
      
}
.parradoSec{
    width: 100%;
   
}

.buttonContainer{
    
    width: 100%;
}
}