.heroMain{
    display: flex;
    grid-area: 2rem;
    margin: -3rem 10% ;
    background-color: var(--blanco);
    border-radius:10px ;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.156);
   
    gap: 1rem;
    z-index: 1;
}

.cardMain{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius:10px ;
    padding:20px;
    text-align: center;
    background-color: var(--blanco);
}
.cardMain:hover{
    background-color: #F80050;
    color: var(--blanco);
}
.cardMain:hover p{
    
    color: var(--blanco);
}
.cardMain:hover .iconMian{
    
    color: var(--blanco);
}
.cardMain:hover h3{
    
    color: var(--blanco);
}
.cardMain h3{
    color: var(--color1);
}
.cardMain p{
    color: var(--text-color2);
    font-size: 14px;
}
.iconMian{
    color: var(--color1);
    font-size: 20px;
}

@media (max-width: 1024px){
    .heroMain{
        flex-wrap: wrap;
        flex-direction: column;
        margin: 3rem 6% ;
    }
}