.btn-more {
    background-color: var(--color1);
    padding: 10px;
    color: var(--blanco);
    border-radius: 10px;
    width: 10rem;
    text-align: center;
    cursor: pointer;
    border: none;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 500px;
    background-color: var(--blanco);
    padding: 20px;
    border-radius: 8px;
    outline: none;
    overflow: hidden;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 200;
}


.demos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.demo-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.demo-item img {
    width: 30px;
    height: 30px;
}
.deFlexAnc{
    display: flex;
    gap: 10px;
    align-items: center;
}
.demo-item a{
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    color: var(--text-color2);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.demo-item a:hover{
   background-color: var(--gris2);
}

