.Developer{
    background-color: var(--color1);
    padding: 20px;
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--gris2);

}
.Developer p{
    color: var(--blanco);
    cursor: pointer;
}
.Developer a{
    color: var(--blanco);
}




.modal-dev{
    top: 0;
    background-color: var(--blanco);
    width: 65vh;
    height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px ;
    border: none;
 
}

.overlay-dev {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.modal-dev-contain{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    border: 1px solid var(--gris2);
    height: 100%;
    margin: 20px;
    border-radius: 10px;
   
}

.modal-dev-contain h2{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: var(--text-color2);
}
.modal-dev-contain h2 a{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: var(--color1);
}
.modal-dev-contain button{
    background-color: var(--color1);
    border-radius: 10px;
    border: none;
    padding: 10px 20px;
    color: var(--blanco);
    margin-top: 3rem;
 
}

.text-dev-modal{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

}

.text-dev-modal img{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
    border: 2px solid var(--blanco);
    margin-top: -4rem;
}

.banner-dev-modal {
    height: 150px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    
}

.socialsDev{
    display: flex;
    gap: 1rem;
   
}

.socialsDev a{
    color: var(--text-color2);
    font-size: 18px;
    border: 1px solid var(--gris2);
    width: 100%;
    border-radius: 10px;
}

#linkedin{
    padding: 10px 30px;
    border-radius: 10px;
}
#linkedin:hover{
   background-color: rgb(30, 48, 184);
   color: var(--blanco);
}
#github{
    padding: 10px 30px;
    border-radius: 10px;
}
#github:hover{
    background-color: rgb(70, 92, 113);
    color: var(--blanco);
 }
#whatsapp{
    padding: 10px 30px;
    border-radius: 10px;
}

#whatsapp:hover{
    background-color: rgb(30, 184, 30);
    color: var(--blanco);
 }
@media (max-width: 900px){
    .banner-dev-modal {
        height: 100px;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        
    }
    .text-dev-modal img{
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: cover;
        border: 2px solid var(--blanco);
        margin-top: -3rem;
    }
    .modal-dev-contain{
        margin: 10px;
       
    }
    .modal-dev{
        width: 65vh;
        height: auto;
     
    }
    .modal-dev-contain h2{
       font-size: 20px;
    }
    .Developer{
        padding: 20px  0 80px ;
    
    }
    .Developer p{
        padding: 20px  3% 0;
    
      text-align: center;
    }
    
}